<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
      <tr>
        <th>Origine</th>
        <th>Montant</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Montant des travaux</td>
        <td>{{ dossier.montant_travaux }} €</td>
      </tr>
      <tr v-if="!hasMaitriseOeuvreInHonoraires">
        <td>Maîtrise d'oeuvre</td>
        <td>{{ dossier.maitrise_oeuvre }} €</td>
      </tr>
      <tr v-for="honoraire in dossier.honoraires" :key="honoraire.id">
        <td>{{ honoraire.origine }}</td>
        <td>{{ honoraire.montant }} €</td>
        <td v-if="!readOnly">
          <EditEmploiDialog @reload="$root.$emit('reload-financements')" :honoraire="honoraire"/>
        </td>
        <td v-if="!readOnly">
          <v-icon @click="deleteHonoraire(honoraire.id)" color="red">mdi-trash-can</v-icon>
        </td>
      </tr>
      <tr v-if="!hasMaitriseOeuvreInHonoraires">
        <td class="font-weight-bold">Total</td>
        <td>{{ total }} €</td>
      </tr>
      <tr v-if="!readOnly">
        <td v-if="customOrigine">
          <v-text-field type="text" :rules="[value => value !== null]" label="Origine"
                        v-model="honoraireModel.origine"></v-text-field>
        </td>
        <td v-else>
          <v-select :rules="[value => value !== null]" label="Origine" :items="origines_emploi" item-value="value"
                    item-text="value" v-model="honoraireModel.origine"></v-select>
        </td>
        <td>
          <v-text-field :rules="[value => value !== null]" type="number" step="0.01" label="Montant"
                        v-model="honoraireModel.montant"></v-text-field>
        </td>
        <td>
          <v-icon @click="addHonoraire" large color="primary">mdi-plus</v-icon>
        </td>
      </tr>
      <tr v-if="!readOnly">
        <td colspan="3">
          <v-checkbox v-model="customOrigine" label="Entrer l'origine manuellement"></v-checkbox>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
import EditEmploiDialog from "@/views/EditEmploiDialog.vue";

export default {
  name: 'Honoraires',
  components: {EditEmploiDialog},
  props: {
    dossier: {},
    readOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      customOrigine: false,
      honoraireModel: {
        dossier: this.dossier.id,
        origine: null,
        montant: null,
      }
    }
  },
  methods: {
    async addHonoraire() {
      this.honoraireModel.dossier = this.dossier.id;
      let honoraire = await this.$store.dispatch("honoraire/postHonoraire", this.honoraireModel);
      if (!honoraire) return;
      this.honoraireModel = {
        dossier: this.dossier.id,
        origine: null,
        montant: null,
      }
      let honoraires = await this.$store.dispatch("honoraire/fetchHonorairesByDossierId", this.dossier.id);
      if (honoraires !== undefined) {
        this.$store.commit("dossier/setHonoraires", honoraires);
      }
      await this.$store.dispatch("annonce/annonceSuccess", "Honoraire ajouté.");
      this.$root.$emit("reload-financements");
    },
    async deleteHonoraire(honoraireId) {
      await this.$store.dispatch("honoraire/deleteHonoraire", {id: honoraireId});
      let honoraires = await this.$store.dispatch("honoraire/fetchHonorairesByDossierId", this.dossier.id);
      if (honoraires !== undefined) {
        this.$store.commit("dossier/setHonoraires", honoraires);
      }
      await this.$store.dispatch("annonce/annonceSuccess", "Honoraire supprimé.");
      this.$root.$emit("reload-financements");
    },

  },
  computed: {
    origines_emploi() {
      return this.$store.getters["select/origines_emploi"];
    },
    total() {
      return this.dossier.honoraires.reduce((acc, honoraire) => acc + parseFloat(honoraire.montant), 0)
          + parseFloat(this.dossier.montant_travaux)
          + (this.hasMaitriseOeuvreInHonoraires ? 0 : parseFloat(this.dossier.maitrise_oeuvre));
    },
    hasMaitriseOeuvreInHonoraires() {
      let moe = this.dossier.honoraires.find(honoraire => honoraire.origine === "Maîtrise d'oeuvre");
      return moe !== undefined;
    }
  }
}
</script>